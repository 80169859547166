import { render, staticRenderFns } from "./auto-bpap-s.vue?vue&type=template&id=5f466c27&scoped=true"
import script from "./auto-bpap-s.vue?vue&type=script&lang=js"
export * from "./auto-bpap-s.vue?vue&type=script&lang=js"
import style0 from "./auto-bpap-s.vue?vue&type=style&index=0&id=5f466c27&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f466c27",
  null
  
)

export default component.exports